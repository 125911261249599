import consumer from "./consumer"

const userId = window.userId; // Ensure this is set correctly
console.log("User ID:", userId);

consumer.subscriptions.create({ channel: "ReportProgressChannel", user_id: userId }, {
  connected() {
    // Called when the subscription is ready for use on the server
    console.log("Connected to ReportProgressChannel");
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
  },

  received(data) {
    console.log("Received data from ReportProgressChannel");
    console.log(data)
    // Called when there's incoming data on the websocket for this channel
    const progressBar = document.getElementById("report-progress-bar");
    const progressText = document.getElementById('report-progress-text');
    const downloadLink = document.getElementById('download-link');

    if (progressBar && progressText) {
        console.log("Received progress update:", data);

        progressBar.style.width = `${data.progress}%`;
        progressText.textContent = `${data.progress}%`;

        if (data.progress >= 100 && downloadLink) {
            downloadLink.href = data.download_link;
            downloadLink.style.display = 'block'; // Show the download link when progress reaches 100%
        }
    }
  }
});
