// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

global.$ = global.jQuery = jQuery;
window.$ = window.jQuery = jQuery;

import "bootstrap"
import "../stylesheets/application"
import "./sb_admin/sb-admin"
import "jquery.easing"
import "datatables.net-bs4"
import "datatables.net-buttons-bs4"
import "datatables.net-buttons/js/buttons.flash"
import jsZip from "jszip"
import "pdfmake"
import "./vfs_fonts"
import "datatables.net-buttons/js/buttons.html5"
import "datatables.net-buttons/js/buttons.print"
import "datatables.net-buttons/js/buttons.colVis"
import "daterangepicker"
import "chart.js"
import "gasparesganga-jquery-loading-overlay"

import './companies'
import './chart_area'
import './financed_full_report_datepicker'
import './range_datepicker'
import './form_validation'

import moment from 'moment'
window.moment = moment;
window.JSZip = jsZip;

function enableTooltips() {
    $('[data-toggle="tooltip"]').tooltip()
    $('[data-toggle="popover"]').popover()
}

document.addEventListener("turbolinks:load", () => {
    enableTooltips()
    window.setTimeout(function () {
        $(".alert-messages").fadeTo(5000, 0).slideUp('slow', function () {
            $(this).remove();
        });
    }, 5000);
})

$(document).ready(function () {
    window.setTimeout(function () {
        $(".alert-messages").fadeTo(5000, 0).slideUp('slow', function () {
            $(this).remove();
        });
    }, 5000);
});

enableTooltips()
// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
