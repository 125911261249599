function getUrlVars() {
    var vars = {};
    var parts = window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(m,key,value) {
        vars[key] = value;
    });
    return vars;
}

function dateRangePicker() {
    var moment = require('moment');
    var params = getUrlVars();

    page_start_date = $('#range-datepicker').attr('data-start-date')
    page_end_date = $('#range-datepicker').attr('data-end-date')

    if (page_start_date) {
        var start_date = params.start_date || moment(page_start_date).format('YYYY-MM-DD')
    } else {
        var start_date = params.start_date || moment().format('YYYY-MM-DD')
    }

    if (page_end_date) {
        var start_date = params.start_date || moment(page_end_date).format('YYYY-MM-DD')
    } else {
        var end_date = params.end_date ||moment().format('YYYY-MM-DD')
    }

    $('#range-datepicker span').html(start_date + ' - ' + end_date);

    $('#range-datepicker').daterangepicker({
        locale: {format: 'YYYY-MM-DD'},
        startDate: start_date,
        endDate: end_date,
        drops: 'down',
        ranges: {
            'Today': [moment(), moment()],
            'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
            'Last 7 Days': [moment().subtract(6, 'days'), moment()],
            'Last 30 Days': [moment().subtract(29, 'days'), moment()],
            'This Month': [moment().startOf('month'), moment().endOf('month')],
            'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
            'Last 2 Months': [moment().subtract(2, 'months').startOf('month'), moment().endOf('month')],
            'Last 3 Months': [moment().subtract(3, 'months').startOf('month'), moment().endOf('month')]
        }
    }, function (start, end, label) {
        $('#range-datepicker span').html(start.format('YYYY-MM-DD') + ' - ' + end.format('YYYY-MM-DD'));
        $.LoadingOverlay("show", {
            image       : "",
            fontawesome : "fas fa-spinner fa-spin"
        });

        Turbolinks.visit('?start_date=' + start.format('YYYY-MM-DD') + '&end_date=' +
            end.format('YYYY-MM-DD'))
    })

    $.LoadingOverlay("hide");
}

document.addEventListener("turbolinks:visit", function () {
    $.LoadingOverlay("show", {
        image       : "",
        fontawesome : "fas fa-spinner fa-spin"
    });
})

document.addEventListener("turbolinks:before-visit", function () {
    $.LoadingOverlay("show", {
        image       : "",
        fontawesome : "fas fa-spinner fa-spin"
    });
})

document.addEventListener("turbolinks:load", function () {
    dateRangePicker()
})

dateRangePicker()
