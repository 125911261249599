function displayCharts() {
    if ($("#targetPieChart").length) {
        var ctx = $("#targetPieChart");
        new Chart(ctx, {
            type: 'doughnut',
            data: {
                labels: ctx.data('foobar')[0],
                datasets: [{
                    data: ctx.data('foobar')[1],
                    backgroundColor: ctx.data('foobar')[2],
                    hoverBackgroundColor: ctx.data('foobar')[3],
                    hoverBorderColor: "rgba(234, 236, 244, 1)",
                }],
            },
            options: {
                maintainAspectRatio: false,
                tooltips: {
                    backgroundColor: "rgb(255,255,255)",
                    bodyFontColor: "#858796",
                    borderColor: '#dddfeb',
                    borderWidth: 1,
                    xPadding: 15,
                    yPadding: 15,
                    displayColors: false,
                    caretPadding: 10,
                },
                legend: {
                    display: false
                },
                cutoutPercentage: 80,
            },
        });
    }
}

function displayDatatable() {
    let paths = window.location.toString().split("/");
    let file_name = paths[paths.length - 1] + "_" + moment().format('YYYY-MM-DD');
    let table = $('#datatable').DataTable({
        ordering: false,
        aLengthMenu: [
            [10, 50, 100, 200, -1],
            [10, 50, 100, 200, "All"]
        ],
        iDisplayLength: 10,
        buttons: [
            {extend: 'copyHtml5', title: file_name, className: 'refresh_button btn-sm'},
            {extend: 'excelHtml5', title: file_name, className: 'refresh_button btn-sm'},
            {extend: 'csvHtml5', title: file_name, className: 'refresh_button btn-sm'},
            {extend: 'pdfHtml5', title: file_name, className: 'refresh_button btn-sm'},
        ]
    });

    table.buttons().container()
        .appendTo('#datatable_length.dataTables_length');

    $('#distributor').on('change', function () {
        table.search(this.value).draw();
    })

    $('#statuses_filter').on('change', function () {
        table.search(this.value).draw();
    })
}

function displayRetailerLeadsDatatable() {
    let paths = window.location.toString().split("/");
    let file_name = paths[paths.length - 1] + "_" + moment().format('YYYY-MM-DD');
    let table = $('#retailer_leads_datatable').DataTable({
        ordering: false,
        aLengthMenu: [
            [10, 50, 100, 200, -1],
            [10, 50, 100, 200, "All"]
        ],
        iDisplayLength: 10,
        buttons: [
            {extend: 'copyHtml5', title: file_name, className: 'refresh_button btn-sm'},
            {
                extend: 'excelHtml5',
                title: file_name,
                className: 'refresh_button btn-sm'
            },
            {
                extend: 'csvHtml5',
                title: file_name,
                className: 'refresh_button btn-sm'
            },
            {
                extend: 'pdfHtml5',
                title: file_name,
                className: 'refresh_button btn-sm'
            },
        ]
    });

    table.buttons().container()
        .appendTo('#retailer_leads_datatable_length.dataTables_length');

    $('#statuses_filter').on('change', function () {
        let county = $('#counties_filter').val()
        let type = $('#business_type_filter').val()
        table.column(6).search(this.value).column(5).search(county).column(2).search(type).draw();
    })

    $('#counties_filter').on('change', function () {
        let statuss = $('#statuses_filter').val()
        let type = $('#business_type_filter').val()
        table.column(5).search(this.value).column(6).search(statuss).column(2).search(type).draw();
    })

    $('#business_type_filter').on('change', function () {
        let county = $('#counties_filter').val()
        let statuss = $('#statuses_filter').val()
        table.column(2).search(this.value).column(5).search(county).column(6).search(statuss).draw();
    })
}

// Prevent code to be rerun twice during page reload
var ready
ready = function () {
    if ($('body').attr('data-loaded') === 'T') {
        return
    }

    // Your code below
    displayDatatable()
    displayRetailerLeadsDatatable()
    displayCharts()
    if ($(window).width() > 768) $("li.nav-item.active div").show()
    return $('body').attr('data-loaded', 'T')
}

$(document).ready(ready)
$(document).on('turbolinks:load', ready)
