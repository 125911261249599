function financeFullReport() {
    var moment = require('moment');
    var start_date = moment().format('YYYY-MM-DD');
    var end_date = moment().format('YYYY-MM-DD');

    $('#financed_full_report_datepicker span').html(start_date + ' - ' + end_date);

    $('#financed_full_report_datepicker').daterangepicker({
        locale: {format: 'YYYY-MM-DD'},
        startDate: start_date,
        endDate: end_date,
        drops: 'down',
        ranges: {
            'Today': [moment(), moment()],
            'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
            'Last 7 Days': [moment().subtract(6, 'days'), moment()],
            'Last 30 Days': [moment().subtract(29, 'days'), moment()],
            'This Month': [moment().startOf('month'), moment().endOf('month')],
            'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
        }
    }, function (start, end, label) {
        $('#deposits_datepicker span').html(start.format('YYYY-MM-DD') + ' - ' + end.format('YYYY-MM-DD'));
        var url = '/reports/finance_full_reports/' + '?start_date=' + start.format
            ('YYYY-MM-DD') +
            '&end_date=' + end.format('YYYY-MM-DD') + '&format=js'

        $.get(url, function (data, status) {
        });
    });
}

document.addEventListener("turbolinks:load", function () {
    "use strict";
    financeFullReport();
});

financeFullReport();
